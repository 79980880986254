<template lang="pug">
  v-container#upgrade(fluid='' tag='section')
    v-snackbar(v-model='snackbar.show' :timeout='30000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(:timeout='30000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' @click.native='snackbar.show = false' icon='')
    //-     v-icon mdi-close
    v-dialog(v-model='newRegisterDialog' persistent max-width='400')
      v-card
        v-card-title.headline
          | Registro {{doubts.date}}
        v-card-text
          v-row
            v-col(cols="12" md="12")
              v-text-field(v-model='doubts.reason' label='Motivo')
            v-col(cols="12" md="12")
              v-textarea(label='Comentario:' v-model='doubts.commentary')
        v-card-actions
          v-spacer
            v-btn(color='red darken-1' text='' @click='newRegisterDialog = false')
              | Cancelar
            v-btn(color='blue darken-1' @click="addNew" :loding="loadingNew")
              | Aceptar
    v-dialog(v-model='responseDialog' persistent max-width='400')
      v-card
        v-card-title.headline
          | Nuevo Comentario {{doubts.date}}
        v-card-text
          v-row
            v-col(cols="12" md="12")
              v-textarea(label='Comentario:' v-model='response.commentary')
        v-card-actions
          v-spacer
            v-btn(color='red darken-1' text='' @click='responseDialog = false')
              | Cancelar
            v-btn(color='blue darken-1' @click="addResponse" :loding="loadingResponse")
              | Aceptar
    v-row(justify='center')
      v-col(cols='12' md='8')
        base-material-card(color='primary')
          template(v-slot:heading='')
            .display-2.font-weight-light
              | Envíanos tus dudas y te contestaremos lo antes posible
          //- <!--h2 Lista de Registros-->
          div(v-for='(doubt, index) in lstDoubts' :key='index')
            v-card(v-if="doubt.commentary" style="BACKGROUND: GRAY;")
              v-card-title.text-right
                div.text-right(style="width: 100%;")
                  | {{doubt.commentary.reason.toUpperCase()}}
              v-card-subtitle {{doubt.commentary.date}}
              v-card-text
                | {{doubt.commentary.commentary.toUpperCase()}}
                div(v-if="doubt.response" v-for='(response, resIdx) in doubt.response' :key='resIdx')
                  v-card(:outlined="false")
                    v-card-text
                      | {{response.commentary.toUpperCase()}}
              v-card-actions
                v-spacer
                  v-btn(color='red darken-1' text='' @click="addNewCommentary(doubt.id)" :loding="loadingResponse")
                    | Agregar Comentario
          div.text-center(v-if="lstDoubts.length == 0")
            h2(style="color: red;") SIN REGISTROS
          div.text-right
            v-btn.mr-0(color='primary' @click='addNewRegister')
              | NUEVO
</template>

<script>
  export default {
    name: 'DashboardDashboard',
    data: () => ({
      idRegister: null,
      responseDialog: false,
      loadingResponse: false,
      loadingNew: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
        place: '',
      },
      newRegisterDialog: false,
      doubts: {
        reason: '',
        commentary: '',
        date: new Date().toISOString().substr(0, 10),
      },
      response: {
        commentary: '',
      },
    }),
    computed: {
      lstDoubts () {
        return this.$store.state.users.doubts
      },
    },
    methods: {
      addResponse () {
        if (this.response.commentary === '') {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Debes agregar el comentario para continuar',
          }
          return
        }
        this.loadingNew = true
        const dataSend = {
          id: this.idRegister,
          commentary: this.response.commentary,
        }
        this.$store.dispatch('users/addResponse', dataSend).then(res => {
          this.loadingResponse = false
          this.responseDialog = false
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Registro agregado correctamente',
          }
        })
      },
      addNewCommentary (id) {
        this.responseDialog = true
        this.idRegister = id
        this.response = {
          commentary: '',
        }
      },
      addNewRegister () {
        this.newRegisterDialog = true
        this.doubts = {
          reason: '',
          commentary: '',
          date: new Date().toISOString().substr(0, 10),
        }
      },
      addNew () {
        if (this.doubts.reason === '') {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Debes agregar el motivo para continuar',
          }
          return
        }
        if (this.doubts.commentary === '') {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Debes agregar el comentario para continuar',
          }
          return
        }
        this.loadingNew = true
        this.$store.dispatch('users/addDoubts', this.doubts).then(res => {
          this.loadingNew = false
          this.newRegisterDialog = false
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Registro agregado correctamente',
          }
        })
      },
    },
  }
</script>
